import Prism from 'prismjs';
import 'prismjs/plugins/line-numbers/prism-line-numbers';
import 'prismjs/components/prism-css';
import 'prismjs/components/prism-scss';
import 'prismjs/components/prism-markup';
import 'prismjs/components/prism-markdown';

class StyleGuide {
	constructor() {
		this.events();
	}

	events() {
		const anchors = document.querySelectorAll('.sg__nav a[href^="#"]');
		this.sections = document.querySelectorAll('.sg__section');
		this.nav = document.querySelector('.sg__nav');
		this.rwdToggle = document.querySelector(".sg__hamburger-icon");

		anchors.forEach(anchor => {
			anchor.addEventListener('click', (event) => this.smoothScroll(event));
		});

		this.initHamburgerDropdownMenu();
		this.setupHighlighter();
	}

	smoothScroll(event) {
		event.preventDefault();

		const targetId = event.target.getAttribute('href').substring(1);
		const targetElement = document.getElementById(targetId);

		if (targetElement) {
			const offsetTop = targetElement.offsetTop;
			window.scrollTo({
				top: offsetTop,
				behavior: 'smooth'
			});
		}

		this.updateActiveClass(targetId);
	}

	updateActiveClass(targetId) {
		const anchors = document.querySelectorAll('.sg__nav a[href^="#"]');

		anchors.forEach(anchor => {
			if (anchor.getAttribute('href').substring(1) === targetId) {
				anchor.classList.add('is-active');
			} else {
				anchor.classList.remove('is-active');
			}
		});

		this.sections.forEach(section => {
			if (section.id === targetId) {
				section.classList.add('is-active');
			} else {
				section.classList.remove('is-active');
			}
		});
	}

	// Mobile Hamburger
	initHamburgerDropdownMenu(){
		const mediaQuery = window.matchMedia('(max-width: 1023px)');

		const handleMediaChange = (e) => {
			if (e.matches) {
				this.rwdToggle.addEventListener("click", this.handleHamburgerClick);
			} else {
				this.rwdToggle.removeEventListener("click", this.handleHamburgerClick);
			}
		};

		mediaQuery.addEventListener('change', handleMediaChange);
		handleMediaChange(mediaQuery); // Initial check
	}

	handleHamburgerClick = () => {
		if (this.rwdToggle.classList.contains('active')) {
			this.closeMobileDropdown();
			this.toggleSlide(this.nav);
		} else {
			this.openMobileDropdown();
			this.toggleSlide(this.nav);
		}
	}

	// Open dropdown
	openMobileDropdown() {
		this.nav.classList.add('is-dropdown-active');
		this.rwdToggle.classList.add('active');
	}

	// Close dropdown
	closeMobileDropdown() {
		this.nav.classList.remove('is-dropdown-active');
		this.rwdToggle.classList.remove('active');
	}

	// Efek fadedown
	toggleSlide(el) {
		const mediaQuery = window.matchMedia('(max-width: 1023px)');

		if (!mediaQuery.matches) {
			el.style.maxHeight = ''; // Reset max-height when not in mobile view
			el.style.transition = ''; // Reset transition when not in mobile view
			el.style.overflowY = ''; // Reset overflow when not in mobile view
			el.removeAttribute('data-max-height'); // Remove custom attribute when not in mobile view
			return;
		}

		let el_max_height = 0;

		if (el.getAttribute('data-max-height')) {
			if (el.style.maxHeight.replace('px', '').replace('%', '') === '0') {
				el.style.maxHeight = el.getAttribute('data-max-height');
			} else {
				el.style.maxHeight = '0';
			}
		} else {
			el_max_height = this.getHeight(el) + 'px';
			el.style.transition = 'max-height 0.3s ease-in-out';
			el.style.overflowY = 'hidden';
			el.style.maxHeight = '0';
			el.setAttribute('data-max-height', el_max_height);
			el.style.display = 'block';

			setTimeout(() => {
				el.style.maxHeight = el_max_height;
			}, 10);
		}
	}

	getHeight(el) {
		let el_style = window.getComputedStyle(el),
			el_display = el_style.display,
			el_position = el_style.position,
			el_visibility = el_style.visibility,
			el_max_height = el_style.maxHeight.replace('px', '').replace('%', ''),
			wanted_height = 0;

		if (el_display !== 'none' && el_max_height !== '0') {
			return el.offsetHeight;
		}

		el.style.position = 'absolute';
		el.style.visibility = 'hidden';
		el.style.display = 'block';

		wanted_height = el.offsetHeight;

		el.style.display = el_display;
		el.style.position = el_position;
		el.style.visibility = el_visibility;

		return wanted_height;
	}

	/* Code Block
	 * -------------------------------------------------- */
	setupHighlighter() {
		const codeBlocks = document.querySelectorAll('.sg__code-block pre code');

		codeBlocks.forEach(codeBlock => {
			// Menambahkan nomor baris
			codeBlock.classList.add('line-numbers');
			// Penyorotan kode
			Prism.highlightElement(codeBlock);
		});
	}

}

const styleGuide = new StyleGuide();
